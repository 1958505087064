// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-commissioned-js": () => import("/opt/build/repo/src/pages/commissioned.js" /* webpackChunkName: "component---src-pages-commissioned-js" */),
  "component---src-pages-diary-js": () => import("/opt/build/repo/src/pages/diary.js" /* webpackChunkName: "component---src-pages-diary-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("/opt/build/repo/src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-personal-js": () => import("/opt/build/repo/src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-places-js": () => import("/opt/build/repo/src/pages/places.js" /* webpackChunkName: "component---src-pages-places-js" */),
  "component---src-pages-portrait-js": () => import("/opt/build/repo/src/pages/portrait.js" /* webpackChunkName: "component---src-pages-portrait-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

